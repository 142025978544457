<template>
  <div class="wrapper">
  <div class="bg">
    <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
  </div>

    <transition name="fade">
    <div class="burger-menu" v-if="isShowBurger" style="display: block;">
      <div class="container">
        <div class="burger-menu__content">
          <div class="burger-menu__list">
            <div class="burger-menu__item" @click="isShowBurger = false">
              <a href="javascript:void(0)" class="burger-menu__link" v-scroll-to="'#about'" :class="{active: active == 1}">О конференции</a>
            </div>
            <div class="burger-menu__item" @click="isShowBurger = false">
              <a href="javascript:void(0)" class="burger-menu__link" v-scroll-to="'#speakers'" :class="{active: active == 2}">Спикеры</a>
            </div>
            <div class="burger-menu__item" @click="isShowBurger = false">
              <a href="javascript:void(0)" class="burger-menu__link" v-scroll-to="'#program'" :class="{active: active == 3}">Программа</a>
            </div>
            <div class="burger-menu__item" @click="isShowBurger = false">
              <a href="javascript:void(0)" class="burger-menu__link" v-scroll-to="'#address'" :class="{active: active == 4}">Адрес</a>
            </div>
          </div>
          <div class="burger-menu__button" @click="isShowBurger = false">
            <a href="javascript:void(0)" class="burger-menu__button-link" v-scroll-to="'#form'">Регистрация</a>
          </div>
        </div>
      </div>
    </div>
    </transition>

    <transition name="fade">
      <div class="header" v-if="isShowHeader" :class="{fixed: windowTop > 5}">
        <div class="container">
          <div class="header__content">
            <div class="header__burger" :class="{'active': isShowBurger}" @click="isShowBurger = !isShowBurger">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="header__socials">
              <div class="header__social">
                <a href="javascript:void(0)" class="header__social-link header__social-link--1"></a>
              </div>
              <div class="header__social">
                <a href="javascript:void(1)" class="header__social-link header__social-link--2"></a>
              </div>
              <div class="header__social">
                <a href="javascript:void(2)" class="header__social-link header__social-link--3"></a>
              </div>
            </div>
            <div class="header__menu">
              <div class="header__menu-item">
                <a href="javascript:void(0)" class="header__menu-link" v-scroll-to="'#about'" :class="{active: active == 1}">О конференции</a>
              </div>
              <div class="header__menu-item">
                <a href="javascript:void(0)" class="header__menu-link" v-scroll-to="'#speakers'" :class="{active: active == 2}">Спикеры</a>
              </div>
              <div class="header__menu-item">
                <a href="javascript:void(0)" class="header__menu-link" v-scroll-to="'#program'" :class="{active: active == 3}">Программа</a>
              </div>
              <div class="header__menu-item">
                <a href="javascript:void(0)" class="header__menu-link" v-scroll-to="'#address'" :class="{active: active == 4}">Адрес</a>
              </div>
            </div>
            <div class="header__button">
              <a href="javascript:void(0)" class="header__button-link" v-scroll-to="'#form'">Регистрация</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="hero" data-scroll-container>
      <div class="container">
        <div class="hero__content block-content">
          <div class="hero__place" data-aos="fade-up" data-aos-delay="200">
            <strong>12 апреля 2024 года</strong>
            <br>Москва, Мясницкая ул., д. 13, стр. 20
            <br>Quattro Space, 2 этаж, зал «Шале»
          </div>
          <div class="hero__title" data-aos="fade-up" data-aos-delay="400">Космическая эффективность</div>
          <div class="hero__text" data-aos="fade-up" data-aos-delay="600">Конференция по продвижению <br class="mob-only">в Telegram <br class="desk-only">от СберСеллера <br class="mob-only">и Magnetto.Pro</div>
          <a href="javascript:void(0)" class="hero__button" data-aos="fade-up" data-aos-delay="800" v-scroll-to="'#form'">Регистрация</a>
        </div>
      </div>
      <div class="hero__element hero__element-1 rellax" data-rellax-speed="-12">
        <div class="hero__element-1-ball"></div>
        <div class="hero__element-1-shadow"></div>
      </div>
      <div class="hero__element hero__element-2" data-aos="zoom-in" data-aos-delay="600">
        <div class="hero__element-2-ball"></div>
        <div class="hero__element-2-flag"></div>
        <div class="hero__element-2-shadow"></div>
      </div>
      <div class="hero__element hero__element-3" data-scroll data-scroll-speed="-5" data-scroll-direction="horizontal">
        <svg width="925" height="192" viewBox="0 0 925 192" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M55.7383 140.115C72.6015 128.221 116.055 104.97 154.964 107.121C193.872 109.272 216.85 127.439 257.856 128.14C322.205 129.238 376.39 113.133 416.471 83.4144C466.573 46.2656 733.457 -68.6023 923.6 64.84" stroke="white" stroke-width="2.444" stroke-linecap="round" stroke-dasharray="9.78 9.78"/>
          <path d="M10.0436 160.641C9.91634 160.761 9.70809 160.712 9.647 160.548L8.02035 156.188C7.97154 156.057 7.98102 155.912 8.04638 155.789L13.6976 145.121C13.7911 144.944 13.9828 144.842 14.1816 144.864L25.5058 146.076L10.0436 160.641Z" fill="white"/>
          <path d="M24.5977 179.899C24.4318 179.995 24.4365 180.236 24.606 180.326L29.6432 182.994C29.7409 183.046 29.8532 183.063 29.9618 183.043L41.464 180.893C41.6762 180.853 41.8372 180.679 41.8602 180.464L43.0696 169.173L24.5977 179.899Z" fill="white"/>
          <path d="M12.3959 169.425L3.48976 175.544M16.3091 176.916L12.438 180.038" stroke="white" stroke-width="0.977599" stroke-linecap="round"/>
          <path d="M15.329 160.855C15.2498 160.754 15.2616 160.609 15.3561 160.523L34.0353 143.453C37.0208 141.146 39.8939 139.3 46.1875 140.04L55.267 141.012C55.4012 141.026 55.4984 141.147 55.484 141.281L54.5119 150.36C53.6402 156.637 50.4232 159.083 47.4376 161.39L26.1904 174.305C26.0848 174.37 25.9479 174.345 25.8714 174.248L15.329 160.855Z" fill="white"/>
          <path d="M44.594 155.562C42.3534 157.294 39.1335 156.881 37.4021 154.641C35.6706 152.4 36.0833 149.18 38.3239 147.449C40.5644 145.717 43.7843 146.13 45.5158 148.371C47.2472 150.611 46.8345 153.831 44.594 155.562Z"  fill="black"/>
        </svg>

      </div>
    </div>
    <div class="about" id="about">
      <div class="container">
        <div class="about__content block-content">
          <div class="about__title title" data-aos="fade-up">Мы будем ждать <br class="mob-only">вас, <br class="desk-only">чтобы:</div>
          <div class="about__cards">
            <div class="about__card" data-aos="fade-up">развенчать мифы, показать, <br>как всё работает на практике</div>
            <div class="about__card" data-aos="fade-up">поделиться свежей аналитикой <br>и последними трендами</div>
            <div class="about__card" data-aos="fade-up">показать лучшие кейсы <br>с разбором результатов</div>
            <div class="about__card" data-aos="fade-up">поделиться конкретными <br>инструментами для использования <br>всего потенциала Telegram Ads</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
    </div>
    <div class="speakers" id="speakers">
      <div class="container">
        <div class="speakers__content block-content">
          <div class="speakers__title title" data-aos="fade-up">Космические <br>спикеры</div>
          <div class="speakers__items" data-aos="fade-up">
            <div class="speakers__item">
              <div class="speakers__item-avatar">
                <img src="@/assets/img/speaker-1.png" alt="">
              </div>
              <div class="speakers__item-name">Кристина <br>Лайус</div>
              <div class="speakers__item-text">Директор по коммуникациям <br>и развитию отношений <br>с партнёрами СберСеллера</div>
            </div>
            <div class="speakers__item">
              <div class="speakers__item-avatar">
                <img src="@/assets/img/speaker-2.png" alt="">
              </div>
              <div class="speakers__item-name">Алексей <br>Федин</div>
              <div class="speakers__item-text">Исполнительный <br>директор</div>
            </div>
            <div class="speakers__item">
              <div class="speakers__item-avatar">
                <img src="@/assets/img/speaker-3.png" alt="">
              </div>
              <div class="speakers__item-name">Анастасия <br>Прилепская</div>
              <div class="speakers__item-text">Директор <br>департамента продаж</div>
            </div>
            <div class="speakers__item">
              <div class="speakers__item-avatar">
                <img src="@/assets/img/speaker-4.png" alt="">
              </div>
              <div class="speakers__item-name">Артём <br>Потапов</div>
              <div class="speakers__item-text">Специалист по трафику, <br>таргетолог Telegram Ads</div>
            </div>
          </div>
        </div>
      </div>
      <div class="speakers__element speakers__element-1" data-aos="fade-up"></div>
      <div class="speakers__element speakers__element-2" data-aos="fade-up"></div>
      <div class="speakers__element speakers__element-3" data-aos="fade-up"></div>
    </div>
    <div class="program" id="program">
      <div class="container">
        <div class="program__content block-content">
          <div class="program__title title" data-aos="fade-up">Программа <br>полёта</div>
          <div class="program__text" data-aos="fade-up">
            <div class="program__text-row">
              <p>9:00-10:00</p>
              <p>Сбор гостей, фуршет</p>
            </div>
            <div class="program__text-row" data-aos="fade-up">
              <p>10:00-10:15</p>
              <p>Приветственное слово</p>
            </div>
            <div class="program__block-1" data-aos="fade-up">
              <h3>Блок 1 «Предполётная подготовка»</h3>
              <div class="program__text-row">
                <p>10:15-11:00</p>
                <p>Пользователи Вселенной Telegram. Развитие и изменение <br>Галактики Telegram Ads в 2022–2024 годах</p>
              </div>
              <div class="program__text-row" data-aos="fade-up">
                <p>11:15-12:00</p>
                <p>Мифы и легенды Галактики Telegram Ads. Пункт <br>управления полётами: DIY vs Full Service</p>
              </div>
              <div class="program__text-row" data-aos="fade-up">
                <p>12:00-12:30</p>
                <p>Кофе-брейк</p>
              </div>
            </div>
            <h3>Блок 2 «Поехали!»</h3>
            <div class="program__text-row" data-aos="fade-up">
              <p>12:30-13:30</p>
              <p>Курс на звёздные лайфхаки</p>
            </div>
            <h3>Блок 3 «Стыковка»</h3>
            <div class="program__text-row" data-aos="fade-up">
              <p>13:30-14:00</p>
              <p>Нетворкинг</p>
            </div>
          </div>
        </div>
      </div>
      <div class="program__element rellax-h"  data-rellax-vertical-speed="0" data-rellax-horizontal-speed="-5" data-rellax-vertical-scroll-axis="x"></div>
    </div>
    <div class="info">
      <div class="container">
        <div class="info__content block-content">
          <div class="info__title title" data-aos="fade-up">Закрытая офлайн-<br>конференция</div>
          <div class="info__text text" data-aos="fade-up">Для представителей брендов <br class="mob-only">и агентств, <br class="desk-only">таргетологов <br class="mob-only">и маркетологов</div>
        </div>
      </div>
    </div>
    <div class="companies">
      <div class="container">
        <div class="companies__content block-content">
          <div class="companies__title title" data-aos="fade-up">В космос <br>вас отправят</div>
          <div class="companies__items">
            <div class="companies__item companies__item--1" data-aos="fade-up">
              <div class="companies__item-row">
                <div class="companies__item-icon sber">
                  <img src="@/assets/img/sber.svg" alt="">
                </div>
                <div class="companies__item-text text">Продажи бесшовного рекламного продукта на всех медиаплощадках, <br class="mob-only">а также развлекательных, <br>навигационных и партнёрских сервисах Сбера</div>
              </div>
            </div>
            <div class="companies__item companies__item--2" data-aos="fade-up">
              <div class="companies__item-title text">при поддержке</div>
              <div class="companies__item-row">
                <div class="companies__item-icon magnetto">
                  <img src="@/assets/img/magnetto.svg" alt="">
                </div>
                <div class="companies__item-text text">Агентство digital-маркетинга, крупнейший <br class="desk-only">реселлер официальной платформы Telegram Ads</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="companies__element" data-aos="fade-up"></div>
    </div>
    <div class="bg">
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
    </div>
    <div class="place" id="address">
      <div class="container" data-aos="fade-up">
        <div class="place__content block-content">
          <div class="place__title title">Время и место <br>старта</div>
          <div class="place__text text">
            <p>Ждем вас на космодроме в центре Москвы!</p>
            <p>Москва, Мясницкая ул., д. 13, стр. 20 <br>Quattro Space, 2 этаж, зал «Шале»</p>
            <p>12 апреля 2024 года, ключ на старт <br class="mob-only">в 9:00</p>
          </div>
        </div>
      </div>
      <div class="place__map" data-aos="fade-up">
        <div id="map" style="width: 100%; height:590px"></div>
      </div>
      <div class="place__element place__element-1"></div>
      <div class="place__element place__element-2"></div>
    </div>
    <div id="form"></div>
    <div class="form">
      <div class="container">
        <div class="form__content block-content">
          <div class="form__title title" data-aos="fade-up" v-if="!submitted">Записаться <br>в экипаж</div>
          <div class="form__wrapper"  data-aos="fade-up" v-if="!submitted">
            <div class="form__text text" data-aos="fade-up">Внимание, количество мест <br class="mob-only">на борту ограничено</div>
            <div class="form__block" data-aos="fade-up">
              <div class="form__field">
                <div class="form__label form__label--error" v-if="!$v.user.name.required && $v.user.name.$dirty">Поле обязательно для заполнения</div>
                <input type="text" placeholder="ФИО" class="form__control" v-model.trim="$v.user.name.$model" :class="{'form__control--error': $v.user.name.$error && $v.user.name.$dirty}">
              </div>
              <div class="form__field">
                <div class="form__label form__label--error" v-if="!$v.user.position.required && $v.user.position.$dirty">Поле обязательно для заполнения</div>
                <input type="text" placeholder="Должность" class="form__control" v-model.trim="$v.user.position.$model" :class="{'form__control--error': $v.user.position.$error && $v.user.position.$dirty}">
              </div>
              <div class="form__field">
                <div class="form__label form__label--error" v-if="!$v.user.company.required && $v.user.company.$dirty">Поле обязательно для заполнения</div>
                <input type="text" placeholder="Компания" class="form__control" v-model.trim="$v.user.company.$model" :class="{'form__control--error': $v.user.company.$error && $v.user.company.$dirty}">
              </div>
              <div class="form__field">
                <div class="form__label form__label--error" v-if="!$v.user.email.required && $v.user.email.$dirty">Поле обязательно для заполнения</div>
                <div class="form__label form__label--error" v-if="!$v.user.email.email && $v.user.email.$dirty">Введите корретный e-mail</div>
                <input type="text" placeholder="E-mail" class="form__control" v-model.trim="$v.user.email.$model" :class="{'form__control--error': $v.user.email.$error && $v.user.email.$dirty}">
              </div>
              <div class="form__checkbox">
                <div class="form__label form__label--error" v-if="!$v.agree.customValidator && $v.agree.$dirty">Необходимо согласиться с условиями</div>
                <div class="custom-checkbox">
                  <input type="checkbox" name="terms" id="terms" v-model.trim="$v.agree.$model">
                  <label for="terms"><span>Я ознакомлен и согласен с <a href="javascript:void(0)">пользовательским соглашением</a></span></label>
                </div>
              </div>
              <div class="form__field">
                <button class="form__button" @click="register" v-if="!submitting">Регистрация</button>
                <button class="form__button" v-else>Отправляем...</button>
              </div>
            </div>
          </div>

          <!--ТЕКСТ ПОСЛЕ ОТПРАВКИ ФОРМЫ-->
          
          <div class="form__wrapper" v-if="submitted">
            <div class="form__text text">Спасибо за регистрацию. <br class="mob-only">В ближайшее время <br class="desk-only">мы свяжемся <br class="mob-only">с вами.</div>
          </div>
          <div class="form__subtitle title">Поехали!</div>
        </div>
      </div>
      <div class="form__element form__element-1"></div>
      <div class="form__element form__element-2"></div>
    </div>
  </div>
</template>

<script>
  const customValidator = (value) => value === true;
  import { disablePageScroll, enablePageScroll } from 'scroll-lock';
  import AOS from 'aos';
  import Rellax from 'rellax';
  import axios from 'axios';
  //import LocomotiveScroll from 'locomotive-scroll';
  import 'aos/dist/aos.css';
  import { required, email } from 'vuelidate/lib/validators'
  export default {
    name: "Main",
    validations: {
      user: {
        name: {
          required,
        },
        company: {
          required,
        },
        position: {
          required,
        },
        email: {
          required,
          email
        },
      },
      agree: {
         customValidator
      }
    },
    data() {
      return {
        submitted: false,
        submitting: false,
        agree: false,
        isShowBurger: false,
        isShowHeader: false,
        windowTop: 0,
        active: 1,
        user: {
          name: "",
          email: "",
          position: "",
          company: "",
        }
      }
    },
    mounted() {
      if (window.innerWidth < 1000) {
        this.isShowHeader = true;
      }
      AOS.init({
        duration: 900
      });
      window.ymaps.ready(this.initMap)
      Rellax('.rellax');
      Rellax('.rellax-h', {horizontal: true});
      window.addEventListener("scroll", this.onScroll, true)
    },
    methods: {
      onScroll() {
        let h = document.documentElement, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        let percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
        this.windowTop = percent;
        console.log(this.windowTop);
        if (this.windowTop > 13) {
          this.isShowHeader = true;
        } else {
          if (window.innerWidth > 1000) {
            this.isShowHeader = false;
          }
        }
        if (this.windowTop < 20) {
          this.active = 1;
        }
        if (this.windowTop > 20 && this.windowTop < 34) {
          this.active = 2;
        }
        if (this.windowTop > 34 && this.windowTop < 70) {
          this.active = 3;
        }
        if (this.windowTop > 70 && this.windowTop < 100) {
          this.active = 4;
        }
      },
      initMap() {
            let coords = [55.763417, 37.629877];
            if (window.innerWidth < 1000) {
              coords = [55.763417, 37.633877]
            }
            const myMap = new window.ymaps.Map("map",{center: coords, zoom: 17});
            myMap.controls.add("zoomControl");            
            let myPlacemark = new window.ymaps.Placemark([55.763417, 37.633877], {
            hintContent: '<div class="map-hint">Конференция по продвижению в Telegram от СберСеллера и Magnetto.Pro</div>'
            }, {
            iconImageHref: '/pin.png',
            iconImageSize: [70, 70],
            iconImageOffset: [-10, -60]
            });
            myMap.geoObjects.add(myPlacemark);
      },
      register() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$toast.error('Заполните все поля корректно');
        } else {
          this.submitting = true;
          axios.post('/form/create/', this.user).then(() => {
            this.$v.$reset();
            this.submitted = true;
            this.submitting = false;
            this.user = {
              name: "",
              email: "",
              position: "",
              company: "",
            }
          })
        }
      }
    },
    watch: {
      isShowBurger(val) {
        if (val == true) {
          disablePageScroll();
        } else {
          enablePageScroll();
        }
      }
    }
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
@import "../sass/styles.sass"
@import "../sass/stars.scss"

.ymaps-layers-pane
  filter: invert(100%)
  -ms-filter: invert(100%)
  -webkit-filter: invert(100%)
  -moz-filter: invert(100%)
  -o-filter: invert(100%)
.map-hint
  color: #000

.form__label--error
  color: #FF0000
  position: static
  margin-bottom: vw(10px)
#form
  position: relative
  top: -40px
</style>